
import './App.css';
import React from "react";
import Home from "./pages/Home";
import Contract from "./pages/Contract";

function App() {
  const navHome = () => {
    if (window.location.pathname === "/") {
      return <Home />
    }
  }
  const navContract = () => {
    if (window.location.pathname === "/contract") {
      return <Contract />
    }
  }
  return (
    <>
      {navHome()}
      {navContract()}
    </>
  );
}

export default App;
