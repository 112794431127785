import React from "react";
import $ from 'jquery';

export default function Home(props) {
  const setAccountListener = (provider) => {
    provider.on("accountsChanged", (accounts) => (connect()));
    provider.on("chainChanged", (networkId) => (connect()));
  };
  const ethEnabled = async () => {
      if (window.ethereum) {
          if(window.ethereum.isMetaMask){
            try{
              await window.ethereum.request({method: 'eth_requestAccounts'});
              return true;
            }catch(e){
              return false;
            }
          }else{
            return false;
          }
      }
      return false;
  }
  const connect = async () => {
      const available = await ethEnabled();
      if(available){
          setAccountListener(window.ethereum);
          const account = window.ethereum.selectedAddress;
          if(account){
            $('#connectBtn').text("("+account.substring(0,5)+ "..." + account.slice(-5) + ")");
          }
      }else{
        alert("Please install metamask to connect.")
      }
  }
  
  $(document).ready(function(){
    connect();
    if($('.parallax1').length){
        // Find the initial scroll top when the page is loaded.
        var initScrollTop = $(window).scrollTop();
        
        // Set the image's vertical background position based on the scroll top when the page is loaded.
        $('.parallax1').css({'background-position' : 'center center'});
        $('.parallax1').css({'background-attachment' : 'scroll'});
        $('.parallax1').css({'background-repeat' : 'no-repeat'});
        $('.parallax1').css({'background-size' : 'cover'});
        $('.parallax1').css({'background-position-y' :'0px'});
        // When the user scrolls...
        $(window).scroll(function() {
            
            // Find the new scroll top.
            var scrollTop = $(window).scrollTop();
            
            // Set the new background position.
            //$('.parallax1').css({'background-position-y' : ((scrollTop)/5)+'%'});
        });
    }
  });
  return (
    <>
    <main>
      <div className="page-loader">
        <div className="loader">Loading...</div>
      </div>
      {/* style="background:url('assets/img/stable-trade-homepage-background-top.webp');background-repeat: no-repeat;background-size: cover;background-color: #000;background-position: top center;" */}
      <section className="home-section home-parallax home-fade home-full-height bg-dark-60 agency-page-header" id="home" Style="background:url('assets/img/stable-trade-homepage-background-top.webp');background-repeat: no-repeat;background-size: cover;background-color: #000;background-position: top center;">
        <nav className="navbar navbar-custom navbar-transparent" role="navigation">
          <div className="container">
            <div className="navbar-header">
              <button className="navbar-toggle" type="button" data-toggle="collapse" data-target="#custom-collapse"><span className="sr-only">Toggle navigation</span><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></button><a className="navbar-brand" href="/"><img src="assets/img/stable-trade-logo.webp" className="img-fluid" alt="" /></a>
            </div>
            <div className="collapse navbar-collapse" id="custom-collapse">
              <ul className="nav navbar-nav navbar-right pt-20">
                <li className="">
                  <a className="" href="#discord">
                    <img src="assets/img/discord-lg.svg" className="img-fluid discord-lg" alt="" />
                  </a>
                </li>
                <li className="">
                    <a href="/contract">
                      <button className="btn btn-connect" type="button" id="connectBtn">CONNECT WALLET</button>
                    </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="titan-caption alt-features-item mt-80">
                <div className="caption-content">
                  <div className="fs-900 text-left">Your bridge between crypto and traditional financial markets with a focus on options trading.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-black">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mt-80 mb-80">
                <h3 className="text-center color-theme fs-26 mt-80"><b>Our Investors</b></h3>
                <h1 className="fs-900 text-center count-item">Earn <span class="count-to" data-countto="1.25">1.25</span>% Daily...</h1>
                <h3 className="text-center fs-26 fw-normal">Transparent And Community Driven</h3>
                <div className="row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-8">
                    <div className="row mt-20 pl-40">
                      <div className="col-lg-6">
                        <div className="alt-features-item">
                          <div className="alt-features-icon"><img src="assets/img/check.svg"  width="33" alt="" /></div>
                          <p className="alt-features-title">Decentralized Application</p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="alt-features-item">
                          <div className="alt-features-icon"><img src="assets/img/check.svg" width="33" alt="" /></div>
                          <p className="alt-features-title">7 day Capital Lock</p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="alt-features-item">
                          <div className="alt-features-icon"><img src="assets/img/check.svg"  width="33" alt="" /></div>
                          <p className="alt-features-title">Doxxed Project Owner</p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="alt-features-item">
                          <div className="alt-features-icon"><img src="assets/img/check.svg" width="33" alt=""  /></div>
                          <p className="alt-features-title">Daily 1.25% Rewards</p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="alt-features-item">
                          <div className="alt-features-icon"><img src="assets/img/check.svg"  width="33" alt="" /></div>
                          <p className="alt-features-title">5+ Years Trading Track Record</p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="alt-features-item">
                          <div className="alt-features-icon"><img src="assets/img/check.svg" width="33" alt=""  /></div>
                          <p className="alt-features-title">No Withdraw Fee</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                  <div className="col-lg-12 mt-80 mb-80">
                    <a className="btn btn-start fw-normal" href="/contract">Get Started</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
      <section className="bg-theme">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="text-center fw-600 fs-32 mt-50 mb-50">By participating in Stable Trade, you can access to covered calls opportunities in many of our option trading strategies.</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-black">
        <div className="container">
          <div className="row mt-80 mb-80">
            <div className="col-lg-12 mt-40 mb-40">
              <h1 className="text-left fs-46 fw-900">What is a covered call?</h1>
              <p className="fs-24 lh-1p4 mt-30">A covered call is an options trading strategy that involves owning a stock and then selling someone else the right to buy that stock from you at a certain price, called the strike price.</p>
              <p className="fs-24 lh-1p4 mt-30">When you sell someone a covered call option, they pay you a premium upfront for the right to buy your stock at the strike price. If the stock price goes up, the person who bought the option might want to buy the stock from you at the lower strike price, so they can make a profit. But if the stock price stays the same or goes down, you get to keep the stock and the premium.</p>
              <p className="fs-24 lh-1p4 mt-30">Traditionally, option traders and stock investors use this strategy as a way to generate semi-passive income on stocks they already own. </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-theme">
        <div className="container">
          <div className="row mt-80 mb-80 pb-50">
            <div className="col-lg-6">
              <h3 className="text-left fs-46 fw-900">Why Covered Calls?</h3>
              <p className="fs-24 lh-1p4 mt-30">One of the primary advantages of selling covered calls is that it can generate income for traders and investors. By selling a call option on a stock that you already own, you can collect the premium paid by the buyer of the option. This can be a useful way to supplement your investment income, particularly during periods of low market volatility or when stock prices are relatively stable.</p>
            </div>
            <div className="col-lg-6">
              <img src="assets/img/covered-calls.webp" className="img-fluid cover-calls" alt="" />
            </div>
            <div className="col-lg-12 mt-40">
              <h3 className="text-left fs-46 fw-900">Leveraging Cryptocurrency</h3>
              <p className="fs-24 lh-1p4 mt-30">At Stable Trade, we participate both in the crypto and stock market to diversify our portfolio and mitigate investors' risk. We have invested our own assets (stocks and crypto) and trade clients' assets with a worldwide network of experienced traders. </p>
              <p className="fs-24 lh-1p4 mt-30">We have a broader exposure to more traditional stock exchanges, commodities (energy, agriculture, metal, livestock, and meat), ETFs, Indexes, and individual stocks. Our covered calls strategies allows us to hold some assets for long term gains while leveraging price volatility and speculation to generate consistent income in the short term. </p>
            </div>
            <div className="col-lg-6 mt-40">
              <img src="assets/img/leverage.webp" className="img-fluid leverage-img" alt="" />
            </div>
            <div className="col-lg-6 mt-40">
              <p className="fs-24 lh-1p4">With Stable Trade, your assets have access to multiple order types and algorithms to help limit risk, speed execution, enhance privacy, provide price improvement and simplify the trading process.  </p>
              <p className="fs-24 lh-1p4 mt-30">Even though our main emphasis is on options trading, our traders are also experienced in futures, currencies, bonds and funds on a global scale.</p>
              <p className="fs-24 lh-1p4 mt-30">For obvious security and the privacy of our investors, we do not disclose who are traders are, what platforms we use, nor which specific stocks that we own.</p>
            </div>
          </div>

        </div>
      </section>
      <section className="bg-black-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mt-80 mb-80">
              <img src="assets/img/current-trades.png" className="img-fluid" alt="" />
              <div className="row mt--80">
                <div className="col-lg-4"></div>
                <div className="col-lg-8">
                  <h2 className="fs-32 fw-normal text-left count-item2">Current Trades Completed:&nbsp;<span class="count-to" data-countto="13527">13,527</span></h2>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-10 mb-80">
              <h1 className="fs-46 fw-900 text-center lh-1p2">Join the thousands of investors like you who are earning 1.25% daily.</h1>
              <a className="btn btn-start fw-normal mt-50 mb-80"  href="/contract">Get Started</a>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
    </section>
    <section className="bg-black-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="fs-46 fw-900 mb-0">ANDY RICHARDS</h2>
              <h6 className="fs-18 mt-0">CEO of Stable Trade</h6>
              <p className="fs-20 mt-30">Welcome to StableTrade!  My goal is to make every effort possible to provide something different in the DEFI space by providing the truth even when the truth may not be easy to hear.  No one can make promises or guarantees in the DEFI space, and if they do, you know right away they are lying.  However I will always do my best to provide a safe investing environment to our members knowing that you are being told the truth and will commit to provide as much transparency as I can to the StableTrade community on a daily basis.To be blunt, I believed StableFund and "Michael" or "Ray", or whoever he WAS, to be real until it was proven otherwise.  Believe me when I say that there is a TON of evidence that StableFund was nothing but a scam from day one.</p>
            </div>
            <div className="col-lg-6  parallax-slide parallax1" Style="background: url(assets/img/andy-richards.webp)">
              <img src="assets/img/andy-richards.webp" Style="opacity: 0;height:700px;" className="img-fluid" alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <p className="fs-20 mt-30">I was so disappointed that I decided to see if I could improve on the StableFund model, patch the holes, correct the lies, and actually make it work.  So welcome to StableTrade!Some of you have already asked what qualifies me to run StableTrade.  What qualified Michale/Ray to run StableFund?  So the short answer may be “nothing”.  But I can tell you that I have been involved in Crypto since BEFORE the creation of BitCoin.  WAY before BitCoin actually.  I was involved in a crypto called “e-gold” (Google it) which began in 1996 and was shut down in approximately 2007.  I made my first million in crypto before BitCoin was even an idea.  I also watched the rise and fall of another crypto in this same timeframe called “e-bullion”. </p>
              <p className="fs-20 mt-30">A few of you may remember these early "e-currencies" from the HYIP world that preceded crypto smart-contracts.  In reality, not much has changed from then to now other than the utility, anonymity, and the automation that smart contracts now provide us.</p>
              <p className="fs-20 mt-30">When bitcoin was first presented to me in 2010 I was among the first to step forward and proclaim that it would never work!  (See, I’m a smart guy!)  However shortly there after I obviously ate a large plate of crow and began investing in bitcoin when it was WELL below $1000.  Since then my business partner and I have in essence retired ourselves several times over again from crypto and other real-world investments and various business endeavors.</p>
              <p className="fs-20 mt-30">StableTrade is my first attempt at developing and managing a smart-contract based business model.  My goal is to bring honesty and transparency front and center of the business model.</p>
              <a className="btn btn-start fw-normal mt-80 mb-80"  href="/contract">Start Earning Today</a>
            </div>
          </div>
        </div>
    </section>
    <section className="bg-black-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="fs-46 fw-900 mt-80 mb-30 text-center">F.A.Q.</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <h3 className="fs-18 color-theme"><b className="fw-900">Q. Is StableTrade a spin-off of the StableFund scam?</b></h3>
              <p className="fs-15 fw-normal lh-1p4">A. We started StableTrade because of what where clearly unstanabile long term which should have been very clear to everyone we have simply put transperancy  and honesty in our model by creating a process to help eliematte the risk of everyones capital.</p>
              <h3 className="fs-18 color-theme"><b className="fw-900">Q. Why Not Show All Of Your Trades?</b></h3>
              <p className="fs-15 fw-normal lh-1p4">A. Anyone that has ever had any direct business dealings with traders that handle seven figure accounts will tell you that traders are a fickle group of people that live in a very unique world.  Let's break this down another way for better clarity.<br/><br/>Let's suppose that you trade the NASDAQ.  Now let's say that you have discovered that every day at 9am when the market opens, you can look at price of crude oil from the previous day's close and you have discovered that if the price was lower at the close of the market than it was at the open, that means that 90% of the time the NASDAQ will go up in the first 15 min of opening.  So every day you place a trade at the market open based on this simple information and 90% of the time you win that trade.<br/><br/>If this were a true scenario and you were a professional trader with millions of dollars under management, you would not tell ANYONE your indicator, you would not tell them you were trading the market open of the NASDAQ, much less would you consider “publishing” your daily trades for the fear of someone figuring out your “secret sauce”.<br/><br/>Let's take this a step further.  Some of the traders and trade groups that we work with do not publish their trades even back to us, therefore there is no way for us to publish those trades to you.What we will show you are the trades from our primary scalping bot as well as any other trades our traders may allow us to publish in the future.  Our advice to you concerning our ability to publish trading results is that if you have any problem or concerns about what we are able to openly share for any reason, simply do not invest.</p>
              <h3 className="fs-18 color-theme"><b className="fw-900">Q. Why not “DOX” the whole Stable Trade team?</b></h3>
              <p className="fs-15 fw-normal lh-1p4">A. We don't believe anyone should have to dox themselves on StableTrade or any other project. These are DEFI projects and no one should invest more than they can afford to lose.  With that said, understanding the current market sentiment the founder of StableTrade Andy Richards has decided to dox himself and operate StableTrade at the highest level known in the DEFI space and be front and center to the community. The need to dox our team is not necessary as Andy Richards is the only one with access to the wallets.</p>
              <h3 className="fs-18 color-theme"><b className="fw-900">Q. How will you handle member support?</b></h3>
              <p className="fs-15 fw-normal lh-1p4"></p>
            </div>
            <div className="col-lg-6">
              <h3 className="fs-18 color-theme"><b className="fw-900">Q. Is 1.25% subsatably long term?</b></h3>
              <p className="fs-15 fw-normal lh-1p4">A. We have been doing this for years and we can say with confidence that it has not been an issue having a net positive greater than 1.25% daily over each 30 day trading window. With that said, only invest what you're willing to lose!  Responsible investors understand that everyday our money is in a trade it is 100% at risk.  We always want to educate our users of the risks involved with trading on any platform, including StableTrade.</p>
              <p className="fs-15 fw-normal lh-1p4">One small but relevant side-note here.  We have a significant portfolio of both online and offline investments and other “real world” endeavors.  Not all of the potential income sources of StableTrade are “trades” at all.  We will be sharing more about these alternative investment strategies over time.  </p>
              <p className="fs-15 fw-normal lh-1p4">Large and consistent investors will potentially have opportunities to participate in private alternative investments with full disclosure to achieve returns over and above our standard 1.25% daily.  Rest assured these private investors will be able to confirm to our community of daily investors that StableTrade is the “real deal” with plans to remain in business for the long haul.</p>
              <h3 className="fs-18 color-theme"><b className="fw-900">Q. Are you going to have the contract audited?</b></h3>
              <p className="fs-15 fw-normal lh-1p4">A.Yes we had the contract audited for our general knowledge and the protection of the community, the nature of our business operations make little to no sense to worry about doing additional kyc or audits of any kind just because some people want to feel better for a millisecond as it will not affect our trades or how we do business.  We would rather those investors simply not invest with StableTrade.</p>
              <h3 className="fs-18 color-theme"><b className="fw-900">Q. Why do you not offer a referral fee?</b></h3>
              <p className="fs-15 fw-normal lh-1p4">A.</p>
              <h3 className="fs-18 color-theme"><b className="fw-900">Q. Why not “DOX” the whole Stable Trade team?</b></h3>
              <p className="fs-15 fw-normal lh-1p4">A.</p>
            </div>
            <div className="col-lg-12 mt-80 mb-80">
              <h3 className="text-center fs-20">Please join us on discord for support and platform updates.<div className="icon-discord icon-base"></div><div className="icon-twitter2 icon-base"></div><div className="icon-tiktok icon-base"></div></h3>
            </div>
          </div>
        </div>
    </section>
      <div className="main">
        
        <hr className="divider-d" />
        <footer className="footer bg-black-2">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                <p className="copyright fs-15">&copy; 2023&nbsp;<a href="/" className="color-theme">Stable Trade.</a> All Rights Reserved</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div className="scroll-up"><a href="#totop"><i className="fa fa-angle-double-up"></i></a></div>
    </main>
      </>
  );
}