/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import $ from 'jquery';
import Web3 from 'web3';
import customAlert from '../components/customAlert';
import moment from "moment";

export default function Contract(props) {
  const bscTestChainId = 97;
  let hedgeBot,UsdcToken,contractAddress,account,web3,payoutAvailable;
  let is_approved = false;
  let nextWithdraw = 0;
  let numdays = 7;
  let numdays2 = 7;
  let devFee = 2;
  let dailyPercentage = 1.25;
  const BigNumber = require('bignumber.js');
  let formatting_options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
  }
  const containsOnlyNumbers = (str) => {
      return /^[0-9]+$/.test(str);
  }
  const bscTestNetwork = {
    chainId:Web3.utils.toHex(bscTestChainId),
    chainName: "BSC Testnet",
    nativeCurrency: {
        name: "BSC Testnet",
        symbol: "tBNB", // 2-6 characters long
        decimals: 18
    },
    rpcUrls: ["https://bsc-testnet.publicnode.com","https://data-seed-prebsc-1-s1.binance.org:8545"],
    blockExplorerUrls:["https://explorer.binance.org/smart-testnet"]
  }
  const tradeDeposit = async () => {
    let amountToDeposit = $('#amountToDeposit').val();
    try{
        if(!containsOnlyNumbers(amountToDeposit)){
            customAlert("Error", "Please enter a valid amount.", "", "", "error");
        }else{
            document.getElementById('tradeDeposit').setAttribute("disabled","disabled");
            $('.page-loader').fadeIn();
            if(!is_approved){
                try{
                    let maxApproval = new BigNumber(2).pow(256).minus(1).toFixed();
                    let hash = await UsdcToken.methods.approve(contractAddress, maxApproval).send({from:account}).on('transactionHash',  (hash) => {
                        console.log(hash);
                    });
                    console.log(hash);
                }catch(e){
                    customAlert("Error", "Approval Cancelled", "", "", "error");
                    document.getElementById('tradeDeposit').removeAttribute("disabled");
                    $('.page-loader').fadeOut();
                    return false;
                }
                is_approved = true;
                document.getElementById('tradeDeposit').textContent = "Deposit";
                document.getElementById('amountToDeposit').setAttribute("disabled","disabled");
                $('.page-loader').fadeOut();
                customAlert(amountToDeposit+ " USDC", "Successfully Approved", "", "", "success");
            }else{
                
                let amount =  web3.utils.toWei(amountToDeposit, "ether");
                try{
                    let hash = await hedgeBot.methods.tradeDeposit(amount).send({from:account}).on('transactionHash',  (hash) => {
                      console.log(hash);
                    });
                    console.log(hash);
                }catch(e){
                    document.getElementById('amountToDeposit').removeAttribute("disabled");
                    document.getElementById('tradeDeposit').removeAttribute("disabled");
                    $('.page-loader').fadeOut();
                    customAlert("Error", "","Deposit Unsuccessful", "","error");
                    return false;
                }
                document.getElementById('amountToDeposit').removeAttribute("disabled");
                document.getElementById('tradeDeposit').textContent = "Approve";
                customAlert(amountToDeposit+ " USDC", "Successfully Deposited", "", "", "success");
                document.getElementById('amountToDeposit').value = '';
                $('.page-loader').fadeOut();
                connect();
            }
            document.getElementById('tradeDeposit').removeAttribute("disabled");
        }
    }catch(e){
        document.getElementById('tradeDeposit').removeAttribute("disabled");
        $('.page-loader').fadeOut();
        // let error = JSON.parse(e.message.replace("Internal JSON-RPC error.\n",""));
        // let message = error.message.charAt(0).toUpperCase() + error.message.slice(1);
        customAlert("Error", "", e.message, "","error");
    }
  };
  const rewardsWithdraw = async () => {
    try{
        let nextTimeStamp = nextWithdraw;
        if(nextTimeStamp){
            let delta = (nextTimeStamp- Math.floor(Date.now() / 1000));
            if(delta <= 0 && parseFloat(payoutAvailable) > 0){
                document.getElementById('rewardsWithdraw').setAttribute("disabled","disabled");
                $('.page-loader').fadeIn();
                await hedgeBot.methods.rewardsWithdraw().send({from:account}).on('transactionHash',  (hash) => {
                  console.log(hash);
                });
                document.getElementById('rewardsWithdraw').removeAttribute("disabled");
                customAlert("Success", "Rewards withdrawn successfully.", "","","success");
                $('.page-loader').fadeOut();
                connect();
            }else{
                customAlert("Rewards are not yet available","","Rewards can only be withdrawn "+numdays+" days after previous withdrawal","","error");
            }
        }else{
            customAlert("Rewards are not yet available","","Rewards can only be withdrawn "+numdays+" days after previous withdrawal","","error");
        }
    }catch(e){
        document.getElementById('rewardsWithdraw').removeAttribute("disabled");
        $('.page-loader').fadeOut();
        customAlert("Error", "",  e.message, "", "error");
    }
  };
  const withdraw = async (index) => {
    try{
        $('.page-loader').fadeIn();
        await hedgeBot.methods.withdrawDeposit(index).send({from:account}).on('transactionHash',  (hash) => {
          console.log(hash);
        });
        
        customAlert("Success", "Amount withdrawn successfully.", "","","success");
        $('.page-loader').fadeOut();
        connect();
    }catch(e){
        $('.page-loader').fadeOut();
        customAlert("Error", "",  e.message, "", "error");
    }
  };
  const reinvest = async (index) => {
    try{
        $('.page-loader').fadeIn();
        await hedgeBot.methods.reInvestDeposit(index).send({from:account}).on('transactionHash',  (hash) => {
          console.log(hash);
        });
        customAlert("Success", "Amount reinvested successfully.", "","","success");
        $('.page-loader').fadeOut();
        connect();
    }catch(e){
        $('.page-loader').fadeOut();
        customAlert("Error", "",  e.message, "", "error");
    }
  };
  $(document).on('click','.btn-withdraw',function(e){
    e.preventDefault();
    e.stopImmediatePropagation();
    $(this).attr("disabled","disabled");
    let index = $(this).closest('tr').data('index');
    withdraw(index);
  });
  $(document).on('click','.btn-reinvest',function(e){
    e.preventDefault();
    e.stopImmediatePropagation();
    $(this).attr("disabled","disabled");
    let index = $(this).closest('tr').data('index');
    reinvest(index);
  });
  const addNetwork = async(networkDetails) => {
    try{
        const ethereum = window.ethereum;
        await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
                networkDetails
            ]
        });
    }catch(err){
        console.log(`error ocuured while adding new chain with chainId:${networkDetails.chainId}, err: ${err.message}`);
        //swal("Error",`Error ocuured while adding new chain with chainId:${networkDetails.chainId}`,'error');
    }
  }
  const switchNetwork = async (chainId) => {
      const provider = window.ethereum;
      if(provider){
          const web3 = new Web3(provider);
          const currentChainId = await web3.eth.getChainId();
          if (currentChainId !== chainId){
              try {
                  await provider.request({
                      method:'wallet_switchEthereumChain',
                      params: [{chainId: web3.utils.toHex(chainId)}]
                  });
                  return true;
              }catch(err){
                  console.log(`error occured while switching chain to chainId ${chainId}, err: ${err.message} code: ${err.code}`);
                  if (err.code === 4902){
                      addNetwork(bscTestNetwork);
                  }
                  return false;
              }
          }else{
            return true;
          }
      }else{
        return false;
      }
  }
  const setAccountListener = (provider) => {
    provider.on("accountsChanged", (accounts) => (connect()));
    provider.on("chainChanged", (networkId) => (connect()));
  };
  
  const convertToSafeNumber  = (BN) => {
    let num = 0
    try{
        let web3 = new Web3(window.ethereum);
        num = Math.round(web3.utils.fromWei(BN, "ether") * 100) / 100;
        num = num.toFixed(2);
    }catch{

    }
    return num;
  }; 
  const reInvestReward = async () => {
    try{
        let nextTimeStamp = nextWithdraw;
        if(nextTimeStamp){
            let delta = (nextTimeStamp- Math.floor(Date.now() / 1000));
            if(delta <= 0 && parseFloat(payoutAvailable) > 0){
                document.getElementById('reInvestReward').setAttribute("disabled","disabled");
                $('.page-loader').fadeIn();
                await hedgeBot.methods.rewardsReinvest().send({from:account}).on('transactionHash',  (hash) => {
                  console.log(hash);
                });
                document.getElementById('reInvestReward').removeAttribute("disabled");
                customAlert("Success", "Rewards invested successfully.", "","","success");
                $('.page-loader').fadeOut();
                connect();
            }else{
                customAlert("Rewards are not yet available","","Rewards can only be invested "+numdays+" days after previous withdrawal","","error");
            }
        }else{
            customAlert("Rewards are not yet available","","Rewards can only be invested "+numdays+" days after previous withdrawal","","error");
        }
    }catch(e){
        document.getElementById('reInvestReward').removeAttribute("disabled");
        $('.page-loader').fadeOut();
        customAlert("Error", "",  e.message, "", "error");
    }
  }
  const currentEarnings = async () => {
    // Claimable Rewards
    payoutAvailable = await hedgeBot.methods.computePayout(account).call();
    payoutAvailable = convertToSafeNumber(payoutAvailable) - 0;
    let rewardsPerDay = payoutAvailable/numdays;
    let rewardsPerHour = payoutAvailable/(numdays*24);
    $('#payoutAvailable').text(`$${payoutAvailable ?  (payoutAvailable).toLocaleString(undefined,formatting_options) : "0.00"} (USDC)`);
    $('#rewardsPerDay').text(`$${rewardsPerDay ?  (rewardsPerDay).toLocaleString(undefined,formatting_options) : "0.00"} (USDC)`);
    $('#rewardsPerHour').text(`$${rewardsPerHour ?  (rewardsPerHour).toLocaleString(undefined,formatting_options) : "0.00"} (USDC)`);

  }
  let grandTimer = [];
  const refreshGrandTimer = () => {
    var timestamp = $('#grandTimer').data('time');
    var g_timer = setInterval(function() {
        var now = Math.floor(Date.now()/1000)*1000;
        var deadline = timestamp*1000;
        var t = deadline - now;
        var days = Math.floor(t / (1000 * 60 * 60 * 24));
        var hours = Math.floor((t%(1000 * 60 * 60 * 24))/(1000 * 60 * 60));
        var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((t % (1000 * 60)) / 1000);
        let timerText = '';
        if (t <= 0) {
            timerText = "Available Now";
            $('#grandTimer').text(timerText);
            if(t%60000 === 0){
              currentEarnings();
            }
            clearInterval(g_timer);
        }else{
          timerText = days+"d "+hours+"h "+minutes+"m "+seconds+"s";
          $('#grandTimer').text(timerText);
          if(t%60000 === 0){
            currentEarnings();
          }
        }
        if(timestamp === 0){
          timerText = numdays+"d 0h 0m 0s";
          $('#grandTimer').text(timerText);
        }
    }, 1000);
    grandTimer.push(g_timer);
  }
  let timers = [];
  const refreshTimers = () => {
    $.each($('.timestamps'),function(i,v){
      var timestamp = $(v).data('time');
      var timer = setInterval(function() {
          var now = Math.floor(Date.now()/1000)*1000;
          var deadline = timestamp*1000 + (parseInt(numdays2)*24*3600*1000);
          var t = deadline - now;
          var days = Math.floor(t / (1000 * 60 * 60 * 24));
          var hours = Math.floor((t%(1000 * 60 * 60 * 24))/(1000 * 60 * 60));
          var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((t % (1000 * 60)) / 1000);
          let timerText = '';
          if (t <= 0) {
              $(v).find('.btn-funds-available').text('Available Now');
              $(v).find('.btn-funds-available').addClass('available');
              $(v).find('.timer').text('0d 0h 0m 0s');
              $(v).find('.btn-withdraw').removeClass('error').removeAttr('disabled');
              $(v).find('.btn-reinvest').removeClass('error').removeAttr('disabled');
              clearInterval(timer);
          }else{
            timerText = days+"d "+hours+"h "+minutes+"m "+seconds+"s";
            $(v).find('.btn-funds-available').removeClass('available');
            $(v).find('.btn-funds-available').text('funds available in');
            $(v).find('.timer').text(timerText);
            $(v).find('.btn-withdraw').addClass('error').attr('disabled','disabled');
            $(v).find('.btn-reinvest').addClass('error').attr('disabled','disabled');
          }
      }, 1000);
      timers.push(timer);
    });
  }
  const ethEnabled = async () => {
      if (window.ethereum) {
          if(window.ethereum.isMetaMask){
            try{
              setAccountListener(window.ethereum);
              const switched = await switchNetwork(97);
              let accountRequested = false;
              if(switched){
                accountRequested = await window.ethereum.request({method: 'eth_requestAccounts'});
              }
              if(accountRequested){
                return true;
              }else{
                return false;
              }
            }catch(e){
              return false;
            }
          }else{
            return false;
          }
      }
      return false;
  }
  const connect = async () => {
      const available = await ethEnabled();
      if(available){
          account = window.ethereum.selectedAddress;
          if(account){
            try{
              web3 = new Web3(window.ethereum);
              // Set Address
              $('#connectBtn').text("("+account.substring(0,5)+ "..." + account.slice(-5) + ")");
              // Load Contract
              const abi = await fetch(`/contracts/StableTrade.json`);
              const StableTradeAbi = await abi.json();
              contractAddress = StableTradeAbi.networks[bscTestChainId].address;
              hedgeBot = new web3.eth.Contract(StableTradeAbi.abi, contractAddress,window.ethereum);
              // USDC TOKEN
              const usdcTokenAddress = await hedgeBot.methods.USD().call();
              const Usdc = await fetch(`/contracts/UsdcToken.json`);
              const USDCTokenAbi = await Usdc.json();
              UsdcToken = new web3.eth.Contract(USDCTokenAbi.abi, usdcTokenAddress,window.ethereum);
              console.log(usdcTokenAddress);
              $('.page-loader').fadeIn();
              // Contract Balance
              let contractBalance = await hedgeBot.methods.getContractBalance().call();
              contractBalance = convertToSafeNumber(contractBalance) - 0;
              $('#contractBalance').text(`$${contractBalance ?  (contractBalance).toLocaleString(undefined,formatting_options) : "0.00"} (USDC)`);
              // Total Days of contract existance
              let contractCreationTime = await hedgeBot.methods.contractCreationTime().call();
              contractCreationTime = parseInt(contractCreationTime) - 0;
              let time_difference = Math.floor(Date.now()/1000) - contractCreationTime;  
              //calculate days 
              let days_since = Math.floor(time_difference / (60 * 60 * 24));  
              $('#daysTrading').text(days_since);
              $('#aprPercentage').text((days_since*1.25)+ " %");

              // Total Deposit
              let totalDeposit = await hedgeBot.methods.invested().call();
              totalDeposit = convertToSafeNumber(totalDeposit) - 0;
              $('#totalInvested').text(`$${totalDeposit ?  (totalDeposit).toLocaleString(undefined,formatting_options) : "0.00"} (USDC)`);

              // Total Users
              let totalUsers =  await hedgeBot.methods.nextMemberNo().call();
              totalUsers = parseInt(totalUsers) - 0;
              $('#totalUsers').text(totalUsers);
              // User Data
              let userdata = await hedgeBot.methods.investors(account).call();
              // Total Invested By User
              let totalInvestedByUser = convertToSafeNumber(userdata.total_invested) - 0;
              $('#totalInvestedByUser').text(`$${totalInvestedByUser ?  (totalInvestedByUser).toLocaleString(undefined,formatting_options) : "0.00"} (USDC)`);

              // Total Rewards to User
              let totalWithdrawnByUser = convertToSafeNumber(userdata.total_withdrawn) - 0;
              $('#totalWithdrawnByUser').text(`$${totalWithdrawnByUser ?  (totalWithdrawnByUser).toLocaleString(undefined,formatting_options) : "0.00"} USDC`);
              
              // USDC WALLET BALANCE
              let usdcBalance = await UsdcToken.methods.balanceOf(account).call();
              usdcBalance = convertToSafeNumber(usdcBalance) - 0;
              $('#usdcBalance').text(`$${usdcBalance ?  (usdcBalance).toLocaleString(undefined,formatting_options) : "0.00"} (USDC)`);

              // Total Rewards
              let totalRewards = await hedgeBot.methods.withdrawn().call();
              totalRewards = convertToSafeNumber(totalRewards) - 0;
              $('#totalRewards').text(`$${totalRewards ?  (totalRewards).toLocaleString(undefined,formatting_options) : "0.00"} (USDC)`);

              
              // Num Days
              numdays = await hedgeBot.methods.numDays().call();
              numdays2 = await hedgeBot.methods.numDays2().call();
              numdays = parseInt(numdays);
              numdays2 = parseInt(numdays2);
              nextWithdraw = await hedgeBot.methods.nextWithdraw(account).call();
              nextWithdraw = parseInt(nextWithdraw);

              // Claimable Rewards
              payoutAvailable = await hedgeBot.methods.computePayout(account).call();
              payoutAvailable = convertToSafeNumber(payoutAvailable) - 0;
              let rewardsPerDay = payoutAvailable/numdays;
              let rewardsPerHour = payoutAvailable/(numdays*24);

              $('#payoutAvailable').text(`$${payoutAvailable ?  (payoutAvailable).toLocaleString(undefined,formatting_options) : "0.00"} (USDC)`);
              $('#rewardsPerDay').text(`$${rewardsPerDay ?  (rewardsPerDay).toLocaleString(undefined,formatting_options) : "0.00"} (USDC)`);
              $('#rewardsPerHour').text(`$${rewardsPerHour ?  (rewardsPerHour).toLocaleString(undefined,formatting_options) : "0.00"} (USDC)`);

              $('#grandTimer').data("time",nextWithdraw);
              $('#grandTimer').attr("data-time",nextWithdraw);
              $(grandTimer).each(function(i,v){clearInterval(v)});
              refreshGrandTimer();
              $('.numdays').text(numdays);
              devFee = await hedgeBot.methods.devFee().call();
              devFee = parseInt(devFee)*0.1;
              $('.devFee').text(devFee);

              const tarifs = await hedgeBot.methods.tarifs(0).call();
              dailyPercentage =  (parseInt(tarifs.percent)/100);
              $('.dailyPercentage').text(dailyPercentage);
              const deposits = await hedgeBot.methods.depositHistory(account).call();
              let tableHtml = '';
              for(let i = deposits.length - 1; i >= 0; i--){
                  tableHtml += `<tr class="mt-20 timestamps" data-time="${parseInt(deposits[i].time)}" data-index="${i}">
                                  <td><p class="fs-14 mb-0 mb-0 mt-0 text-right">${moment.unix(parseInt(deposits[i].time)).format("YYYY-MM-DD h:mm:ss")}</p></td>
                                  <td><p class="fs-14 mb-0 mb-0 mt-0">$${convertToSafeNumber(deposits[i].amount)} USDC</p></td>
                                  <td><p class="fs-14 mb-0 mb-0 mt-0" ><button class="btn-funds-available">funds available in</button></p></td>
                                  <td><p class="fs-16 mb-0 mb-0 mt-0"><b class="timer">0d 0h 0m 0s</b></p></td>
                                  <td>
                                    <p class="fs-14 mb-0 mb-0 mt-0"><button class="btn btn-withdraw" type="button">WITHDRAW</button></p>
                                  </td>
                                  <td>
                                    <p class="fs-14 mb-0 mb-0 mt-0"><button class="btn btn-reinvest" type="button">REINVEST</button></p>
                                  </td>
                                </tr>`;

              }
              if(deposits.length === 0){
                tableHtml = `<tr className="mt-20">
                                <td colspan="6"><p class="fs-14 m-0 text-center">No Deposits Found</p></td>
                            </tr>`;
                document.getElementById('tradeDeposit').textContent = "Approve";
              }else{
                is_approved = true;
                document.getElementById('tradeDeposit').textContent = "Deposit";
              }
              $('#depositHistory tr').remove();
              $('#depositHistory').append(tableHtml);
              $(timers).each(function(i,v){clearInterval(v)});
              refreshTimers();
              $('.page-loader').fadeOut();
            }catch(e){
              customAlert("Error", "There is connection error in the request.", "", "", "error");
              console.log(e);
            }
          }
      }else{
        //customAlert("Error", "We could not connect to metamask.", "", "", "error");
        console.log("Please switch to BSC Network.")
      }
  }
  $(document).ready(function(){
    connect();
  });
  return (
    <>
    <main>
      <div className="page-loader">
        <div className="loader">Loading...</div>
      </div>
      <nav className="navbar navbar-custom navbar-transparent bg-black-2 mb-0" role="navigation">
        <div className="container">
          <div className="navbar-header">
            <button className="navbar-toggle" type="button" data-toggle="collapse" data-target="#custom-collapse"><span className="sr-only">Toggle navigation</span><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></button><a className="navbar-brand" href="/"><img src="assets/img/stable-trade-logo.webp" className="img-fluid" alt="" /></a>
          </div>
          <div className="collapse navbar-collapse" id="custom-collapse">
            <ul className="nav navbar-nav navbar-right pt-20">
              <li className="">
                <a className="" href="#discord">
                  <img src="assets/img/discord-lg.svg" className="img-fluid discord-lg" alt="" />
                </a>
              </li>
              <li className="">
                <a className="" href="/contract">
                  <button className="btn btn-connect" id="connectBtn">CONNECT WALLET</button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      
      <section className="bg-black-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mt-80 mb-80 px-container">
              <h1 className="fs-46 fw-900 text-center lh-1p2 text-uppercase">THE #1 OPTIONS TRADING DEFI PROJECT</h1>
              <h3 className="text-center fs-16 lh-1p4">Earn daily rewards from our covered calls strategies</h3>
              <h4 className="text-left mt-60 text-uppercase fs-20 fw-900">TRANSPARENT & CLEAR CONTRACT</h4>
              <p className="fs-15 lh-1p4">Providing The Community All The Data Required To Feel Confident In DEFI By Doing What We Say We Are Doing Daily To Create A True Sustainable DEFI Project For Years To Come.</p>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="row text-center">
                <div className="col-lg-4 mt-30 mb-30">
                  <div className="block-div">
                    <h4 className="fs-13 lh-1p2 fw-600 ls-1px">CONTRACT BALANCE</h4>
                    <h4 className="fs-14 lh-1p2 fw-600 ls-1px" id="contractBalance">$0.00 (USDC)</h4>
                  </div>
                </div>
                <div className="col-lg-4 mt-30 mb-30">
                  <div className="block-div">
                    <h4 className="fs-13 lh-1p2 fw-600 ls-1px">TOTAL DEPOSITED</h4>
                    <h4 className="fs-14 lh-1p2 fw-600 ls-1px" id="totalInvested">$0.00 (USDC)</h4>
                  </div>
                </div>
                <div className="col-lg-4 mt-30 mb-30">
                  <div className="block-div">
                    <h4 className="fs-13 lh-1p2 fw-600 ls-1px">WITHDRAWN FOR TRADING</h4>
                    <h4 className="fs-14 lh-1p2 fw-600 ls-1px"id="totalRewards">$0.00 (USDC)</h4>
                  </div>
                </div>
                <div className="col-lg-4 mt-30 mb-30">
                  <div className="block-div">
                    <h4 className="fs-13 lh-1p2 fw-600 ls-1px">TOTAL WALLETS</h4>
                    <h4 className="fs-14 lh-1p2 fw-600 ls-1px" id="totalUsers">0</h4>
                  </div>
                </div>
                <div className="col-lg-4 mt-30 mb-30">
                  <div className="block-div">
                    <h4 className="fs-13 lh-1p2 fw-600 ls-1px">APR</h4>
                    <h4 className="fs-14 lh-1p2 fw-600 ls-1px" id="aprPercentage">456.54%</h4>
                  </div>
                </div>
                <div className="col-lg-4 mt-30 mb-30">
                  <div className="block-div">
                    <h4 className="fs-13 lh-1p2 fw-600 ls-1px" >DAYS TRADING</h4>
                    <h4 className="fs-14 lh-1p2 fw-600 ls-1px" id="daysTrading">21</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <h3 className="fs-30 fw-900 currency-title-block mt-50 pb-40"><img src="assets/img/usdc-logo.webp" width="40" alt="" />&nbsp;USDC</h3>
              <div className="row mt-40">
                <div className="col-lg-6">
                  <h3 className="fs-16 mt-0"><strong>DEPOSITED AMOUNT</strong></h3>
                  <p className="fs-14">Total Amount Deposited in StableTrade.</p>
                </div>
                <div className="col-lg-6">
                  <div className="block-div2 text-center">
                    <h4 className="fs-26 lh-1p2 fw-600 ls-1px" id="totalInvestedByUser">$0.00 USDC</h4>
                  </div>
                </div>
              </div>
              <div className="row mt-40">
                <div className="col-lg-6">
                  <h3 className="fs-16 mt-0"><strong>WALLET BALANCE</strong></h3>
                  <p className="fs-14">Total Amount Held On Your Connected Wallet.</p>
                </div>
                <div className="col-lg-6">
                  <div className="block-div2 text-center">
                    <h4 className="fs-26 lh-1p2 fw-600 ls-1px" id="usdcBalance">$0.00 USDC</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
          <div className="row">
            <div className="col-lg-1"></div>
            <hr className="divider-w col-lg-10" /> 
            <div className="col-lg-1"></div>
          </div>
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <h3 className="fs-30 fw-900 currency-title-block mt-50">DEPOSIT</h3>
              <p className="mb-0 fs-14">All deposits are locked for <span class="numdays">{numdays}</span> days.</p>
              <p className="mb-0 fs-14">After the <span class="numdays">{numdays}</span> days, they stop earning rewards and must be reinvested in order to begin earning the <span class="dailyPercentage">{dailyPercentage}</span>% daily rewards.</p>
              <div className="row mt-40">
                <div className="col-lg-11">
                    <div className="form-group mb-10">
                      <div className="input-group">
                        <span className="fw-700 input-group-addon text-muted">
                          USDC
                        </span>
                        <input className="form-control input-lg input-amount text-white" id="amountToDeposit" type="text" placeholder="100" />
                      </div>
                    </div>
                    <h6 className="fs-14 col-lg-12 mt-0">No minimum deposit</h6>
                    <div className="col-lg-12"><button className="btn btn-theme pull-right" id="tradeDeposit" onClick={tradeDeposit}>Approve</button></div>
                    <h6 className="fs-13 col-lg-12 mt-0"><span className="devFee">{devFee}</span>% developper fee will be deducted on all deposits.</h6>
                </div>
                <div className="col-lg-1">
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
          <div className="row">
            <div className="col-lg-1"></div>
            <hr className="divider-w col-lg-10" /> 
            <div className="col-lg-1"></div>
          </div>
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <h3 className="fs-30 fw-900 currency-title-block mt-50">REWARDS</h3>
              <div className="row mt-80">
                <div className="col-lg-6">
                  <p className="mb-0 fs-16 fw-700" id="grandTimer" data-time="">7d 0h 0m 0s</p>
                  <p className="mb-0 fs-16 fw-700 mt-20">CLAIMABLE REWARDS</p>
                  <div className="col-lg-10 mb-10 mt-10 p-0">
                    <div className="block-div2 text-center">
                      <h4 className="fs-26 lh-1p2 fw-600 ls-1px" id="payoutAvailable">$0.00 USDC</h4>
                    </div>
                  </div>
                  <h6 className="col-lg-12 mt-0 p-0 mt-10 mb-30">Rewards Available To Withdraw From Earning <span class="dailyPercentage">{dailyPercentage}</span>% Daily.</h6>
                  <div className="row">
                    <div className="col-lg-6"><button className="btn btn-theme " id="rewardsWithdraw" onClick={rewardsWithdraw}>Claim</button></div>
                    <div className="col-lg-6"><button className="btn btn-theme " id="reInvestReward" onClick={reInvestReward}>Reinvest</button></div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <p className="mb-0 fs-16 fw-700">&nbsp;</p>
                  <p className="mb-0 fs-16 fw-700 mt-20">TOTAL REWARDS</p>
                  <div className="col-lg-10 mb-10 mt-10 p-0">
                    <div className="block-div2 text-center">
                      <h4 className="fs-26 lh-1p2 fw-600 ls-1px" id="totalWithdrawnByUser">$0.00 USDC</h4>
                    </div>
                  </div>
                  <h6 className="col-lg-12 mt-0 p-0 mt-10 mb-30">Total Rewards You Have Earned On StableTrade.</h6>
                </div>
              </div>
              <div className="row mt-80">
                <div className="col-lg-2"></div>
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="block-div3 text-center">
                        <p className="fs-18 fw-600 mb-10">Rewards per day</p>
                        <p className="fs-16 fw-600 mb-0" id="rewardsPerDay">$152.16 USDC</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="block-div3 text-center">
                        <p className="fs-18 fw-600 mb-10">Rewards per hour</p>
                        <p className="fs-16 fw-600 mb-0" id="rewardsPerHour">$12.16 USDC</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2"></div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
          <div className="row mt-80">
            <div className="col-lg-1"></div>
            <hr className="divider-w col-lg-10" /> 
            <div className="col-lg-1"></div>
          </div>
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <h3 className="fs-20 fw-900 currency-title-block mt-50">Deposit History</h3>
            </div>
            <div className="col-lg-1"></div>
          </div>
          <div className="row">
            <div className="col-lg-12 table-responsive">
              <table className="table table-deposit-history">
                <tbody id="depositHistory">
                  <tr className="mt-20">
                    <td colSpan="6"><p class="fs-14 m-0 text-center">No Deposits Found</p></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-80">
            <div className="col-lg-1"></div>
            <hr className="divider-w col-lg-10" /> 
            <div className="col-lg-1"></div>
          </div>
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <h3 className="fs-20 fw-900 currency-title-block mt-50 mb-80">Live covered calls signals</h3>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </section>
      <div className="main">
        <hr className="divider-d" />
        <footer className="footer bg-black-2">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                <p className="copyright fs-15">&copy; 2023&nbsp;<a href="/" className="color-theme">Stable Trade.</a> All Rights Reserved</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div className="scroll-up"><a href="#totop"><i className="fa fa-angle-double-up"></i></a></div>
    </main>
    </>
  );
}